import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`9:00 EMOM, alternating movements:`}</p>
    <p>{`5-Deficit Deadlifts (4”, 60% 1RM)`}</p>
    <p>{`:10 Deadlift Pause below knee`}</p>
    <p>{`5-Deadlifts`}</p>
    <p>{`rest 10:00, then`}</p>
    <p>{`4 rounds of 1:00 on, :30 rest`}</p>
    <p>{`alternating movements for max reps/calories:`}</p>
    <p>{`Double Arm Rope Waves`}</p>
    <p>{`(wave must reach end of rope to count)`}</p>
    <p>{`Calorie Assault Bike`}</p>
    <p>{`(calories round down)`}</p>
    <p>{`KB SDHP’s (53/35)`}</p>
    <p>{`*`}{`score each movement separately for three totals`}{`*`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      